import { useState, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const useUsername = () => {
  const [username, setUsername] = useState<string>('');

  const fetchUsername = useCallback(async () => {
    const {username} = await Auth.currentUserInfo();
    setUsername(username);
  },[]);

  useEffect(()=>{
    fetchUsername();
  },[fetchUsername]);

  return {username, fetchUsername, signOut};
};

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}
