
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const toggleWebgateDeviceMutation = /* GraphQL */ `
  mutation ToggleWebgateDevice(
    $deviceID: ID!
    $services: [WebgateServices!]
    $setEnable: Boolean!
    $flush: Boolean!
  ) {
    toggleWebgateDevice(
      deviceID: $deviceID
      services: $services
      setEnable: $setEnable
      flush: $flush
    ) {
      admin {
        enabled
      }
      solution {
        enabled
      }
    }
  }
`;

export const enableWebgateVPNMutation = /* GraphQL */ `
  mutation EnableWebgateVPN {
    enableWebgateVPN
  }
`;

export const disableWebgateVPNMutation = /* GraphQL */ `
  mutation DisableWebgateVPN {
    disableWebgateVPN
  }
`;