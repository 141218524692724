import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* , body{
  box-sizing: border-box;
}
body, html {
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  height: 100%;
}
html {
  background-repeat: no-repeat, repeat;
  background-image: linear-gradient(to bottom, hsla(210, 20%, 98%, 67%), hsla(210, 20%, 96%, 67%));
  /* background-image: radial-gradient(circle at 55% 1%, #303335, #212427 117%), url(./noise.png); */

}
body {
  font-family: Monorale, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
  color: hsl(0, 0%, 50%);
}
#root {
  height: 100%;
}

.popup-content {
  width: 100%;
  float: right;
  background: transparent;
  padding: 0 37px 0 42px;
  outline: none;
  border: 0;
  overflow: auto;
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-overlay {
  background-color: rgba(239, 244, 247, 0.89);
  backdrop-filter: blur(5px);
}

`;
export default GlobalStyle;
