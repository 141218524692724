import { useState, useCallback } from 'react';
import { getDevices, Device } from '../lib/api_config';




export const useDevices = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>('');
  const fetchDevices = useCallback(async () => {
    setLoading(true);
    try {
      const devices = await getDevices();
      const edgeDevices: Device[] = devices.filter(({type}: Device) => type === 'raspi');
      setDevices(edgeDevices);
    } catch(error){
      setError(error.message);
    }
    setLoading(false);
  },[]);
  return {state: {devices, loading, error}, actions: {fetchDevices, setDevices}};
};

export interface AvailableDevice {
  id: string;
  name: string;
  unavailable: boolean;
}