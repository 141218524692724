import { useCallback, useState } from 'react';
import { useUsername } from './useUsername';


export const useFeatureEnable = () => {
  const [featureEnabled, setFeatureEnabled] = useState<boolean>(true);
  const {username} = useUsername();
  const fetchFeatureEnabled = useCallback(() => {
    try {
      const enable = localStorage.getItem(`${username}_vpn_webgate_enabled`);
      if(enable){
        setFeatureEnabled(enable === 'true');
      }
      else{
        setFeatureEnabled(false);
      }
    } catch(error){
      console.log(error.message);
    }
  },[username]);

  const putFeatureEnabled = useCallback((enable: boolean, username: string) => {
    localStorage.setItem(`${username}_vpn_webgate_enabled`, enable.toString());
    setFeatureEnabled(enable);
  },[]);

  return {state: {featureEnabled}, actions: {fetchFeatureEnabled, putFeatureEnabled}};
};