import { API, graphqlOperation } from 'aws-amplify';
import { GetWebgateStatusQuery, GetWebgateStatusQueryVariables, ToggleWebgateDeviceMutationVariables, ToggleWebgateResponse } from '../API';
import { disableWebgateVPNMutation, enableWebgateVPNMutation, toggleWebgateDeviceMutation } from '../graphql/mutations';

import {
  deviceStatusQuery,
  devicesQuery,
  getWebgateStatusQuery,
  isWebgateEnabledQuery,
} from '../graphql/queries';


export interface LastSnapshot {
  signedUrl: string,
  time: string
}

interface Status {
  alive: boolean,
  diskUsageAlertLevel: string
}

export interface DeviceStatus {
  lastSnapshot: LastSnapshot,
  status: Status
  isImage?: boolean
}

// export interface DeviceVPNInfo {
//   accessLink: string,
//   enabled: string
// }

export interface Device {
  name: string,
  lid: string,
  type: string,
  tags: string[],
  group: string
}

interface DevicesResponse{
  devices: Device[]
}

export const getDevices = async( ): Promise<Device[]> => {
  const { data: {devices}} = await API.graphql(graphqlOperation(devicesQuery)) as { data: DevicesResponse};
  return devices;
};

export const getDeviceStatus = async(deviceID: string): Promise<DeviceStatus> => {
  const { data: {deviceStatus}} = await API.graphql(graphqlOperation(deviceStatusQuery, {deviceID})) as { data: {deviceStatus: DeviceStatus }};
  return deviceStatus;
};

export const getWebgateStatus = async(deviceID: string): Promise<GetWebgateStatusQuery['getWebgateStatus']> => {
  const { data: {getWebgateStatus}} = await API.graphql(graphqlOperation( getWebgateStatusQuery, {deviceID} as GetWebgateStatusQueryVariables)) as {data: GetWebgateStatusQuery} ;
  return getWebgateStatus;
};

export const toggleWebgateDevice = async(deviceID: string, enable: boolean, flush: boolean, services: ('admin'|'solution')[]) => {
  const {data} = await API.graphql(graphqlOperation( toggleWebgateDeviceMutation, {
    deviceID,
    setEnable: enable,
    flush: flush, 
    services
  } as ToggleWebgateDeviceMutationVariables)) as {data: ToggleWebgateResponse} ;
  return data;
};

export const isWebgateEnabled = async() => {
  const {data: {isWebgateEnabled=false}={}} = await API.graphql(graphqlOperation(isWebgateEnabledQuery)) as any;
  return isWebgateEnabled;
};

export const enableWebgateVPN = async():Promise<boolean> => {
  const {data: {enableWebgateVPN = false}={}} = await API.graphql(graphqlOperation(enableWebgateVPNMutation)) as any ;
  return enableWebgateVPN;
};

export const disableWebgateVPN = async():Promise<boolean> => {
  const {data: {disableWebgateVPN = false}={}} = await API.graphql(graphqlOperation(disableWebgateVPNMutation)) as any;
  return disableWebgateVPN;
};