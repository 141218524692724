import env from './config';
const {
  REGION: region,
  COGNITO_USER_POOL_ID: userPoolId,
  COGNITO_IDENTITY_POOL_ID: identityPoolId,
  COGNITO_CLIENT_ID: userPoolWebClientId,
  APPSYNC_API_ENDPOINT
} = env;

const config = {
  aws_appsync_graphqlEndpoint: APPSYNC_API_ENDPOINT,
  aws_appsync_region: region,
  aws_appsync_authenticationType: 'AWS_IAM',
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region,

    // Amazon Cognito User Pool ID
    userPoolId,
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId,

    mandatorySignIn: true,
  }
};

export default config;
