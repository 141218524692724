import React, { HTMLAttributes, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'scorer-ui-kit';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 25px;
  margin-right: 10px;
`;

const SelectFieldContainer  = styled.div`
  display: flex;
  position: relative;
`;

const SelectField = styled.select`
  width: 63px;
  height: 25px;
  border-radius: 3px;
  border: solid 1px #d9dad9;
  background-color: #ffffff;
  color: #808080;
  outline: none;
  appearance: none;
  position: absolute;
  padding-left: 11px;
  background: transparent;
`;

const PageSizeLabel = styled.label`
  padding:0 10px 0 10px;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontFamily.ui };
  font-size: 12px;
  font-weight: 500;
  color: #a4adb5;
`;

const PageLabel = styled.label<{ active: boolean }>`
  padding:0 10px 0 10px;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontFamily.ui };;
  font-size: 12px;
  font-weight: 500;
  color: #a4adb5;
  cursor: pointer;
  pointer-events: ${({ active }) => active ? 'auto' : 'none'};
`;

const PageSizeContainer = styled.div`
  display: flex;
  margin-right: 28px;
`;

const IconWrapper = styled.div`
  margin-left: 40px;
  margin-top: 3px;
`;

interface OwnProps {
  totalPages: number,
  pageSizeOptions?: number[],
  defaultPage?: number,
  defaultPageSize: number,
  onPageSizeChange: (size: number) => void,
  onPageChange: (page: number) => void,
  pageSizeText?: string,
  prevPageText?: string,
  nextPageText?: string,
  firstPageText?: string,
  lastPageText?: string
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>

const Pagination: React.FC<Props> = (props) => {
  const {
    pageSizeOptions = [10, 20, 30, 50, 100],
    defaultPageSize = 10,
    defaultPage = 1,
    pageSizeText = 'Items Per Page',
    prevPageText,
    nextPageText,
    firstPageText,
    lastPageText,
    totalPages,
    onPageSizeChange,
    onPageChange
  } = props;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [activePage, setActivePage] = useState(defaultPage);

  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);
  
  useEffect(() => {
    setActivePage(defaultPage);
  }, [defaultPage]);

  const handlePageSizeChange = useCallback((e)=> {
    onPageSizeChange(parseInt(e.target.value));
  },[onPageSizeChange]);
  
  const handlePageChange = (value: number) => {
    onPageChange(value);
  };

  return (
    totalPages ?
      <PaginationContainer>
        {
          pageSizeOptions &&
            <PageSizeContainer>
              {
                pageSizeText &&
                  <PageSizeLabel>{pageSizeText}</PageSizeLabel>
              }
              <SelectFieldContainer>
                <IconWrapper>
                  <Icon icon='Down' weight='regular' color='dimmed' size={12} />
                </IconWrapper>
                <SelectField value={pageSize} onChange={handlePageSizeChange}>
                  {
                    pageSizeOptions.map((size: number, index: number) => <option key={index} value={size}>{size}</option>)
                  }
                </SelectField>
              </SelectFieldContainer>
            </PageSizeContainer>
        }
        {
          firstPageText &&
            <PageLabel onClick={() => handlePageChange(1)} active={activePage !== 1}>{firstPageText}</PageLabel>
        }
        {
          prevPageText &&
            <PageLabel onClick={() => handlePageChange(activePage - 1)} active={activePage !== 1}>{prevPageText}</PageLabel>
        }
        <SelectFieldContainer>
          <IconWrapper>
            <Icon icon='Down' weight='regular' color='dimmed' size={12} />
          </IconWrapper>
          <SelectField value={activePage} onChange={(event) => handlePageChange(parseInt(event.target.value))}>
            {
              Array.from(Array(totalPages), (e, i) => 
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              )
            }
          </SelectField>
        </SelectFieldContainer>
        {
          nextPageText &&
            <PageLabel onClick={() => handlePageChange(activePage + 1)} active={activePage !== totalPages}>{nextPageText}</PageLabel>
        }
        {
          lastPageText &&
            <PageLabel onClick={() => handlePageChange(totalPages)} active={activePage !== totalPages}>{lastPageText}</PageLabel>
        }
      </PaginationContainer>
      : null
  );
};

export default Pagination;
