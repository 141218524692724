import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Common_en from './locales/en/Common.json';
import Common_ja from './locales/ja/Common.json';

import WebGateway_en from './locales/en/WebGateway.json';
import WebGateway_ja from './locales/ja/WebGateway.json';

import Usage_en from './locales/en/Usage.json';
import Usage_ja from './locales/ja/Usage.json';


import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
  en: {
    Common: Common_en,
    Webgateway: WebGateway_en,
    Usage: Usage_en
  },
  ja: {
    Common: Common_ja,
    Webgateway: WebGateway_ja,
    Usage: Usage_ja
  }
};

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ja',
    lng: localStorage.getItem('language') || navigator.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: 'Common',
    ns: ['Common'],
  });

export default i18n;