import React, { useState, useCallback, useEffect, ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { Content, PageHeader, TypeTable, useNotification, INotificationProps, useCopyToClipboard, resetButtonStyles, Button, useModal, Switch, IconButtonData, ActionButtons, MultilineContent, Icon } from 'scorer-ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import { ITypeTableData, ITableColumnConfig, IRowData } from 'scorer-ui-kit/dist/Tables';
import { useDevices } from '../hooks/useDevices';
import { useWindowSize } from '../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import GhostLogo from '../svg/ghost-logo.svg';
import { LoginScreen } from '../svg';
import { useFeatureEnable } from '../hooks/useFeatureEnable';
import Pagination from '../components/Pagination';
import SortBy, {ISortBy, ISortItem} from '../components/molecules/SortBy';
import SelectMulti  from '../components/molecules/SelectMulti';
import Select from '../components/molecules/Select';
import SearchInput from '../components/atoms/SearchInput';
import { Device, DeviceStatus, enableWebgateVPN, getDeviceStatus, getWebgateStatus, isWebgateEnabled, toggleWebgateDevice } from '../lib/api_config';
import { GetWebgateStatusQuery } from '../API';
import { FeatureEnabledContext, ContextProps, GatewayModalContext } from '../App';

const Wrapper = styled.div <{ enableContent?: boolean }>`
  opacity: ${({ enableContent }) => enableContent ? '1' : '0.6'};
  pointer-events: ${({ enableContent }) => enableContent ? 'auto' : 'none'};
`;

const PageHeaderContainer = styled(PageHeader)`
  max-width: 720px;
`;

const PageHeaderText = styled.div`
  max-width: 610px;
  height: 75px;
  padding: 0 0 10px 0;
  font-size: 14px;
  // font-style: italic;
  line-height: 1.79;
  color: #8b9196;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  margin-bottom: 28px;
`;

const SearchFieldContainer = styled.div`
  margin-bottom: 41px;
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
`;

const ResultInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 71px;
`;

const SortLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 500;
  color: #61696f;
`;

const ResultCountLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.data};
  position: relative;
  display: flex;
  flex-flow: row wrap;
  max-width: 500px;
  font-size: 14px;
  color: #898f95
`;

const SortAttributeLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: bold;
  color: #61696f;
`;

const PaginationContainer = styled.div`
  margin-bottom: 30px;
`;

const FilterText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 11px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
  color: hsl(210, 5%, 56%);
`;

const InputWrapper = styled.div`
  svg{
    position: absolute;
    left: 10px;
    top: 12px;
    fill: hsl(0, 0%, 0%,0.32);
    stroke: hsl(0, 0%, 0%,0.32);
    z-index: 1;
  }
`;

const FilterInput = styled(SearchInput)`
  & input {
    box-sizing: border-box;
    width: 269px;
    height: 40px;
    background-color: #ffffff;
    flex-shrink: 0;
    ::placeholder {
      opacity: 0.5;
      color: hsl(0, 0%, 53%);
    }
  }
`;

const SepratorContainer = styled.div`
  border-left: 2px solid rgb(240, 240, 240);
  box-sizing: border-box;
  height: auto;
  margin-right: 10px;
`;

const FilterContainer = styled.div`
  padding-left: 10px;
  display: flex;
`;

const FilterListIcon = styled.div`
  margin-top: 2px;
`;

const ClearItem = styled(FilterListIcon)`
  cursor: pointer;
`;

/// --- Table Style --- ///

const CategoryTagContainer = styled.div <{width?: number}>`
  max-width: ${({ width }) => width === 1920 ? '250px' : '150px'};
  overflow-y: hidden;
  overflow-x: auto;
  overscroll-behavior: auto;
  scrollbar-width: none;
  opacity: 1;
  background: linear-gradient(to left, transparent);
  ::-webkit-scrollbar {
    display: none;
  }
`;

const EmptyContainer = styled.span`
  margin-left: 40px;
`;

const TableLinkRow = styled.div`
  display: flex;
  max-width: 185px;
`;

const TableRow = styled.div`
  display: flex;
`;

const LinkLayout = styled.div`
  max-width: 185px;
`;

const TableTagCategoryColumn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 3px;
  border: solid 1px #d9dad9;
  padding: 4px 11px 4px 8px;
  height: 26px;
`;

const ColumnText = styled.p`
  margin-left: 8px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  color: #606466;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
`;

const IconWrapper = styled.div`
  margin-top: 4px;
`;

const SwitchContainer = styled.div`
  float: right;
  line-height: initial;
  padding-bottom: 10px;
  height: 26px;
`;

const TableColumnLink = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 5px 4px 0;
  height: 26px;
`;

const SolutionColumnLink = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0 4px 5px;
  height: 26px;
`;

const ActionButtonsContainer = styled(ActionButtons)`
  max-width: 17px;
`;

const LinkContainer = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #4699d4 !important;
  padding-bottom: 5px;
`;

const LinkMessageSeparator = styled.div`
  max-width: 10px;
`;

/// --- Custom Modal Template --- ///

const LogoBackground = styled.img`
  position: absolute;
  top: -542px;
  left: -570px;
  max-width: 1076px;
  height: auto;
  object-fit: contain;
  mix-blend-mode: overlay;
`;

const Logo = styled(LoginScreen)`
  max-width: 350px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 49.8%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled.button`
  ${resetButtonStyles};
  color: hsl(0, 0%, 65%);
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.79;
  color: hsl(0, 0%, 65%);
  display: block;
  margin: 10px 0 0 0;
`;

const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 10px;
  ${StyledButton} {
    margin: 12px auto 0 auto;
  }
`;

const CardCover = styled.div`
    height: 210px;
    border-radius: 5px 5px 0 0;
    position: relative;
    z-index:99;
    background-image: linear-gradient(114deg, hsl(250, 60%, 62%), hsl(0, 46%, 54%));
    overflow: hidden;
  `;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: hsl(210, 6%, 47%);
  margin-bottom: 10px;
`;

const SubCardContainer = styled.div`
  margin: 0 0 34px 0;
`;

const SubCardContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #7c7e7f;
  margin: 13px 0 25px 0;
`;

const CardContent = styled.div`
  padding: 28px 38px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(200, 1%, 49%);
`;

const CardModal = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlushModelContent = styled.div`
  margin: 48px 6px 40px 0;;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const FlushButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlushModelCancel = styled(Button)`
  width: auto;
  margin-right: 5px;
`;

const FlushModelConfirm = styled(Button)`
  width: auto;
`;

const ClearLinkAll = styled.label`
  width: auto;
  margin: 2px 0 0 10px;
  opacity: 0.6;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #676e74;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`;

interface DeviceStatusMap {
  [deviceID: string]: DeviceStatus;
}

type WebgateDeviceStatus = GetWebgateStatusQuery['getWebgateStatus'];

interface DeviceInfoMap {
  [deviceID: string]: WebgateDeviceStatus;
}

interface DeviceToggleStateMap {
  [deviceID: string]: 'default' | 'disabled' | 'loading' | 'locked' | 'failure' | undefined;
}

const WebGateway: React.FC<{username:string}> = ({username}) => {
  const [isFeatureEnabledLoading, setIsFeatureEnabledLoading ] = useState(false);
  const [width] = useWindowSize();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [searchText, setSearchText] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {actions:{ putFeatureEnabled}} = useFeatureEnable();
  const { isFeatureEnabled, setIsFeatureEnabled }: ContextProps = useContext(FeatureEnabledContext);
  const [tags, setTags] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [statusUpdate, setStatusUpdate] = useState<DeviceStatusMap>({});
  const [vpnStatusUpdate, setVpnStatusUpdate] = useState<DeviceInfoMap>({});
  const sizeOptions = [10, 20, 50, 100];
  const {state: {devices, loading}, actions:{fetchDevices}} = useDevices();
  const { t } = useTranslation(['Webgateway', 'Common']);
  const { setModalOpen, createModal } = useModal();
  const [modalCreated, setModalCreated] = useState(false);
  const {copyToClipboard} = useCopyToClipboard();
  const params = useLocation().search;
  const [historyParams] = useState<any>(params);
  const history = useHistory();
  const [deviceToggleState, setDeviceToggleState] = useState<DeviceToggleStateMap>({});
  const [sortBy, setSortBy] = useState<ISortBy>({
    sort:'name',
    ascending: true,
    title: t('Webgateway:sorting.deviceName')
  });
  const [sortByList, setSortByList] = useState<ISortItem[]>([]);
  const { modalClose } = useContext(GatewayModalContext);
  const showModalPopupFirstTime  = localStorage.getItem(`${username}_show_modal_popup_first_time`);

  const generateSortByList = useCallback(() => {
    const sortByList: ISortItem[] = [
      {
        sort: 'name',
        title: t('Webgateway:sorting.name')
      },
      {
        sort: 'category',
        title: t('Webgateway:sorting.category')
      },
      {
        sort: 'tags',
        title: t('Webgateway:sorting.tags')
      }];
    setSortByList(sortByList);
  },[t]);

  useEffect(()=> {
    generateSortByList();
  },[generateSortByList, t]);

  const { sendNotification } = useNotification();

  const notiInfoSuccessEnabled: INotificationProps = {
    type: 'success',
    message: t('Webgateway:notificationMessage.successMessage'),
  };

  const notiInfoDisabled: INotificationProps = {
    type: 'info',
    message: t('Webgateway:notificationMessage.disableMessage'),
    actionTextButton: t('Webgateway:notificationMessage.enableMessageNow'),
    onTextButtonClick: () => enableWebgate(),
    isPinned: false,
  };

  const enableWebgate = useCallback(async () => {
    await enableWebgateVPN();
    putFeatureEnabled(true, username);
    setIsFeatureEnabled(true);
    sendNotification(notiInfoSuccessEnabled);
  },[sendNotification, notiInfoSuccessEnabled, setIsFeatureEnabled, putFeatureEnabled, username]);

  const custom: ReactElement = (
    <CardModal>
      <CardCover>
        <Logo />
        <LogoBackground src={GhostLogo} />
      </CardCover>
      <CardContent>
        <CardTitle>{t('Webgateway:modalPopup.modalTitle')}</CardTitle>
        <SubCardContainer>
          <SubCardContent>{t('Webgateway:modalPopup.modalSubTitle1')}</SubCardContent>
          <SubCardContent>{t('Webgateway:modalPopup.modalSubTitle2')}</SubCardContent>
        </SubCardContainer>
        <ButtonGroup>
          <Button
            onClick={() => {
              setModalOpen(false);
              enableWebgate();
              localStorage.setItem(`${username}_show_modal_popup_first_time`, 'false');
            }}
          >
            {t('Webgateway:modalPopup.enableNow')}
          </Button>
          <StyledButton
            onClick={() => {
              sendNotification(notiInfoDisabled);
              setModalOpen(false);
              localStorage.setItem(`${username}_show_modal_popup_first_time`, 'false');
            }}
          >
            {t('Webgateway:modalPopup.enableLater')}
          </StyledButton>
        </ButtonGroup>
      </CardContent>
    </CardModal>
  );

  const setParams = useCallback(() => {
    const params = '?' +
      `${searchText === '' ? '' : '&searchText=' + searchText}` +
      `${selectedCategory === '' ? '' : '&selectedCategory=' + selectedCategory}` +
      `${selectedTag.length === 0 ? '' : '&selectedTag=' + JSON.stringify(selectedTag)}` +
      `${sortBy.ascending && sortBy.sort === 'name' ? '' : '&sortBy=' + JSON.stringify(sortBy)}` +
      `${pageSize === 10 ? '' : '&pageSize=' + pageSize}` +
      `${currentPage === 1 ? '' : '&currentPage=' + currentPage}`;
    if (params === '?') {
      return '';
    }
    return params;
  }, [selectedCategory, selectedTag, sortBy, pageSize, currentPage, searchText]);

  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams: any = {};
    newParams.selectedCategory = string.get('selectedCategory');
    newParams.selectedTag = string.get('selectedTag');
    newParams.sortBy = string.get('sortBy');
    newParams.pageSize = string.get('pageSize');
    newParams.currentPage = string.get('currentPage');
    newParams.searchText = string.get('searchText');

    if (newParams.selectedCategory) {
      setSelectedCategory(newParams.selectedCategory);
    }
    if (newParams.selectedTag) {
      setSelectedTag(JSON.parse(newParams.selectedTag));
    }
    if (newParams.sortBy) {
      setSortBy(JSON.parse(newParams.sortBy));
    }
    if (newParams.pageSize) {
      setPageSize(parseInt(newParams.pageSize));
    }
    if (newParams.currentPage) {
      setCurrentPage(parseInt(newParams.currentPage));
    }
    if (newParams.searchText) {
      setSearchText(newParams.searchText);
    }
    if (newParams.totalPages) {
      setTotalPages(parseInt(newParams.totalPages));
    }
  }, [historyParams]);

  useEffect(()=>{
    fetchHistoryParams();
  },[fetchHistoryParams]);

  useEffect(() => {
    history.push('/' + setParams());
  }, [history, setParams]);

  const checkIsWebgateEnabled = useCallback(async () => {
    const enabled = await isWebgateEnabled();
    setIsFeatureEnabled(enabled);
    setIsFeatureEnabledLoading(true);
  },[setIsFeatureEnabled]);

  useEffect(()=>{
    checkIsWebgateEnabled();
  },[checkIsWebgateEnabled]);

  const welcomeModal = useCallback(() => {
    setIsFeatureEnabledLoading(false);
    createModal({
      isCloseEnable: false,
      width: '480px',
      padding: false,
      customComponent: custom,
    });
  }, [createModal, custom]);

  useEffect(() => {
    if(isFeatureEnabledLoading) {
      if(showModalPopupFirstTime === null && isFeatureEnabled ) {
        return;
      } else if (showModalPopupFirstTime === null && !isFeatureEnabled ) {
        if(modalClose){
          return;
        } else {
          welcomeModal();
          setModalCreated(true);
        }
      } else {
        return;
      }
    }
  }, [welcomeModal, modalCreated, isFeatureEnabled, modalClose, showModalPopupFirstTime, isFeatureEnabledLoading]);

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('Webgateway:tableHeaders.deviceName'),
      sortable: false,
      cellStyle: 'firstColumn',
      width: 200
    },
    {
      header: t('Webgateway:tableHeaders.categoriesTags'),
      sortable: false,
      cellStyle: 'normalImportance',
    },
    {
      header: t('Webgateway:tableHeaders.accessLinks'),
      sortable: false,
      cellStyle: 'normalImportance',
    },
    {
      header: t('Webgateway:tableHeaders.flush'),
      sortable: false,
      cellStyle: 'normalImportance',
    },
    {
      header: t('Webgateway:tableHeaders.enabled'),
      sortable: false,
      alignment: 'right',
      cellStyle: 'normalImportance',
    }
  ];

  const generateTagCategoryColumn = useCallback((group: string, tags: string[]) : ReactElement[] =>  {
    return (
      [
        <CategoryTagContainer key='' width={width}>
          {
            group === '' && tags.length === 0 &&
              <span>
                -
              </span>
          }

          <TableRow>
            {
              group !== '' &&
                <TableTagCategoryColumn>
                  <IconWrapper>
                    <Icon icon='MetaCategories' color='dimmed' size={14} />
                  </IconWrapper>
                  <ColumnText title={group}>
                    {group}
                  </ColumnText>
                </TableTagCategoryColumn>
            }
            {
              tags.length !== 0 &&
                tags.map((tag, key) => (
                  <TableTagCategoryColumn key={key}>
                    <IconWrapper>
                      <Icon icon='MetaTags' color='dimmed' size={14} />
                    </IconWrapper>
                    <ColumnText title={tag}>
                      {tag}
                    </ColumnText>
                  </TableTagCategoryColumn>
                ))
            }
          </TableRow>
        </CategoryTagContainer>
      ]
    );
  }, [width]);

  const disableToggleDevice = useCallback(async (deviceID: string)=>{
    try {
      const enabled = vpnStatusUpdate[deviceID]?.admin?.enabled || false;
      await toggleWebgateDevice(deviceID, !enabled, false, ['admin','solution']);
      const vpnUpdateStatus = await Promise.all([
        getWebgateStatus(deviceID)
      ]);

      vpnStatusUpdate[deviceID] = vpnUpdateStatus[0];
      setVpnStatusUpdate({...vpnStatusUpdate});

    } catch (error) {
      sendNotification({
        type:'error',
        message: t('Webgateway:notificationMessage.errorToggle')
      });
      console.error('Toggle Error', error.message);
    }
  },
  [sendNotification, vpnStatusUpdate, t]);

  const enableToggleDevice = useCallback(async( deviceID: string, deviceName: string)=>{
    try {
      const enabled = vpnStatusUpdate[deviceID]?.admin?.enabled || false;
      await toggleWebgateDevice(deviceID, !enabled, false,  ['admin','solution']);
      deviceToggleState[deviceID] = 'loading';
      setDeviceToggleState({...deviceToggleState});
      const vpnConnectionCreated: INotificationProps = {
        type: 'success',
        message: t('Webgateway:notificationMessage.vpnConnectionCreated').replace('{deviceName}', deviceName),
      };

      // const vpnConnectionFailed: INotificationProps = {
      //   type: 'error',
      //   message: t('Webgateway:notificationMessage.vpnConnectionFailed').replace('{deviceName}', deviceName),
      //   actionTextButton: t('Webgateway:notificationMessage.retryNow'),
      //   onTextButtonClick: () => enableToggleDevice(deviceID, deviceName),
      // };

      const interval = setInterval(async()=>{
        const getVpnStatus =  await getWebgateStatus(deviceID);
        vpnStatusUpdate[deviceID] = getVpnStatus;
        setVpnStatusUpdate({...vpnStatusUpdate});
        const enabled = await isWebgateEnabled();
        if(getVpnStatus?.admin?.status === 'inprogress') {
          deviceToggleState[deviceID] = 'loading';
          setDeviceToggleState({...deviceToggleState});
        }
        if(getVpnStatus?.admin?.status === 'active') {
          deviceToggleState[deviceID] = 'default';
          setDeviceToggleState({...deviceToggleState});
          sendNotification(vpnConnectionCreated);
          clearInterval(interval);
        }
        if(!enabled) {
          deviceToggleState[deviceID] = 'default';
          setDeviceToggleState({...deviceToggleState});
          clearInterval(interval);
        }
      },3000);

    } catch (error) {
      sendNotification({
        type:'error',
        message: t('Webgateway:notificationMessage.errorToggle')
      });
      console.error('Toggle Error', error.message);
    }
  },[sendNotification, vpnStatusUpdate, t, deviceToggleState]);

  const generateSwitchColumn  = useCallback((deviceID: string, deviceName: string, status: string, enabled: boolean): ReactElement[] => {
    const onChangeEnabled = (event: boolean) => {
      if(event) {
        enableToggleDevice(deviceID, deviceName);
      } else {
        disableToggleDevice(deviceID);
      }
    };

    return (
      [
        <SwitchContainer key=''>
          <Switch key={Math.random()} state={deviceToggleState[deviceID]} checked={enabled} onChangeCallback={onChangeEnabled} />
        </SwitchContainer>
      ]
    );
  },[enableToggleDevice, disableToggleDevice, deviceToggleState]);

  const generateLinkColumn = useCallback((accessLink: string, solutionLink: string, enabled: boolean, status: string): ReactElement[] => {
    accessLink = accessLink === '-' ? '': 'https://' + accessLink;
    solutionLink = solutionLink === '-' ? '': 'https://' + solutionLink;
    return (
      [
        <LinkLayout key=''>

          {!enabled &&
            <EmptyContainer> - </EmptyContainer>}
          {enabled && accessLink !== '' && solutionLink !== '' && status === 'inprogress' &&
            <div><LinkMessageSeparator /> Service Starting ... </div>}
          {status === null &&
            <div><LinkMessageSeparator /> Error While Enabling </div>}
          <TableLinkRow>
            {accessLink !== '' && status === 'active' &&
              <TableColumnLink>
                <IconWrapper onClick={() => copyToClipboard(accessLink)}>
                  <Icon icon='Link' color='dimmed' size={14} />
                </IconWrapper>
                <ColumnText>
                  <LinkContainer href={accessLink} target='_blank' rel='noopener noreferrer'>{accessLink === '#' ? '-': t('Webgateway:deviceUI')}</LinkContainer>
                </ColumnText>
              </TableColumnLink>}
            {solutionLink !== '' && status === 'active' &&
              <SolutionColumnLink>
                <IconWrapper onClick={() => copyToClipboard(accessLink)}>
                  <Icon icon='Link' color='dimmed' size={14} />
                </IconWrapper>
                <ColumnText>
                  <LinkContainer href={solutionLink} target='_blank' rel='noopener noreferrer'>{solutionLink === '#' ? '-': t('Webgateway:solutionUI')}</LinkContainer>
                </ColumnText>
              </SolutionColumnLink>}
          </TableLinkRow>
        </LinkLayout>
      ]
    );
  },[t, copyToClipboard]);

  const getCategoryList = useCallback(() => {
    let categoryList: string[] = [];
    devices.forEach(({group}) => {
      if(group !== ''){
        categoryList.push(group);
      }
    });
    categoryList = categoryList.filter((n: string, i: number) => categoryList.indexOf(n) === i);
    return categoryList;
  },[devices]);

  const getTagList = useCallback(() => {
    let tagList: string[] = [];
    devices.forEach(({tags}) => {
      tags.forEach((tag: string) => {
        tagList.push(tag);
      });
    });
    tagList = tagList.filter((n: string, i: number) => tagList.indexOf(n) === i);
    return tagList;
  },[devices]);

  useEffect(()=>{
    fetchDevices();
  },[fetchDevices]);

  const sortDevices = useCallback((devices:  Device[]) => {
    if (sortBy.sort === 'name') {
      devices.sort((a: Device, b: Device) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        return 0;
      });
    }
    if (sortBy.sort === 'category') {
      devices.sort((a: Device, b: Device) => {
        if (a.group.toLowerCase() > b.group.toLowerCase()) return 1;
        if (a.group.toLowerCase() < b.group.toLowerCase()) return -1;
        return 0;
      });
    }
    if (sortBy.sort === 'tags') {
      devices.sort((a: Device, b: Device) => {
        if (a.tags.sort().join().toLowerCase() > b.tags.sort().join().toLowerCase()) return 1;
        if (a.tags.sort().join().toLowerCase() < b.tags.sort().join().toLowerCase()) return -1;
        return 0;
      });
    }
    if (!sortBy.ascending) {
      devices.reverse();
    }
    return devices;
  }, [sortBy]);

  const filterDevice = useCallback((devices) => {
    const filter: any = [];
    devices.forEach((device: Device) => {
      const { name, group, tags } = device;
      if ((group.includes(selectedCategory) || selectedCategory === '') && (!selectedTag.length || selectedTag.every( tag => tags.includes(tag))) && (name.toLowerCase().includes(searchText.toLowerCase()) || searchText === '')) {
        filter.push(device);
      }
    });
    const totalPage =  Math.ceil(filter.length/pageSize);
    setTotalPages(totalPage);
    return filter;
  }, [searchText, selectedCategory, selectedTag, pageSize]);

  const handleFlushJob = useCallback(async(deviceID: string, enabled: boolean, flush: boolean) => {
    try {
      await toggleWebgateDevice(deviceID, !enabled, flush, ['admin', 'solution']);
      const vpnUpdateStatus = await Promise.all([
        getWebgateStatus(deviceID)
      ]);
      vpnStatusUpdate[deviceID] = vpnUpdateStatus[0];
      setVpnStatusUpdate({...vpnStatusUpdate});
    } catch (error) {
      sendNotification({
        type:'error',
        message: t('Webgateway:notificationMessage.errorFlush')
      });
      console.error('Toggle Error', error.message);
    }
  },[sendNotification, t, vpnStatusUpdate]);

  const generateConfigButtons  = useCallback((deviceId: string, deviceName: string, flush: boolean, enabled: boolean ) : IconButtonData[] => {
    const flushContent = t('Webgateway:confirmationPopup.flushContent').replace('{deviceName}', deviceName);
    const confirmModal: ReactElement =
      <CardModal>
        <CardTitle>{t('Webgateway:confirmationPopup.confirmModalTitle')}</CardTitle>
        <FlushModelContent>
          {flushContent}
        </FlushModelContent>
        <FlushButtonContainer>
          <FlushModelCancel design='secondary' size='normal' onClick={() => setModalOpen(false)}>{t('Webgateway:confirmationPopup.cancel')}</FlushModelCancel>
          <FlushModelConfirm design='primary' size='normal' onClick={() => { handleFlushJob(deviceId, enabled, true); setModalOpen(false); }}>{t('Webgateway:confirmationPopup.confirm')}</FlushModelConfirm>
        </FlushButtonContainer>
      </CardModal>;

    const openConfirmationModal = () => {
      createModal({ isCloseEnable: false, customComponent: confirmModal });
    };

    return (
      [
        {
          icon: '',
        },
        {
          icon: 'ClearField',
          size: 17,
          onClick: () => {
            openConfirmationModal();
          }
        }
      ]
    );
  },[handleFlushJob, createModal, setModalOpen, t]);

  const checkImage = useCallback((url, lid) =>  {
    if (!url) return;
    const img = new Image();
    img.src = url;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve({
        'isImage': true,
        'lid': lid
      });
      img.onerror = reject;
    });
  }, []);

  const loadImages = useCallback(async(statusMap) => {
    let res: any;
    const deviceStatusPromises = Object.keys(statusMap).map(async(lid: string) => {
      try {
        res = await checkImage(statusMap[lid].lastSnapshot.signedUrl, lid);
      } catch (e) {
      // console.log("image failed", e);
      }
      return { res };
    });
    for await(const { res } of deviceStatusPromises){
      if (res?.lid)
        statusMap[res.lid].isImage = true;
    }
    setStatusUpdate({...statusMap});
  }, [checkImage]);

  const fetchDeviceStatus = useCallback(async () => {
    const statusMap: DeviceStatusMap = {};
    const vpnStatusUpdate: DeviceInfoMap = {};
    const deviceToggleState: DeviceToggleStateMap ={};

    const deviceStatusPromises = devices.map(async({lid}) => {
      const [status, vpnStatus] = await Promise.all([
        getDeviceStatus(lid),
        getWebgateStatus(lid)
      ]);
      return {deviceID: lid, status, vpnStatus};
    });

    for await(const {deviceID, status, vpnStatus} of deviceStatusPromises){
      statusMap[deviceID] = status;
      vpnStatusUpdate[deviceID] = vpnStatus;
    }
    loadImages(statusMap);
    setStatusUpdate({...statusMap});
    setVpnStatusUpdate({...vpnStatusUpdate});
    setDeviceToggleState({...deviceToggleState});
  }, [devices, loadImages]);

  useEffect(()=>{
    fetchDeviceStatus();
  },[fetchDeviceStatus]);

  const generateRowData = useCallback((): ITypeTableData => {
    const emptyRow : ITypeTableData = [
      {
        columns: []
      }
    ];
    let filterData = filterDevice(devices);
    let sortedDevices = sortDevices(filterData);
    const start = pageSize * (currentPage - 1);
    let end = sortedDevices.length;
    if (sortedDevices.length > pageSize * currentPage) {
      end = pageSize * currentPage;
    }
    const data: ITypeTableData = sortedDevices.slice(start, end).map((device: Device) => {
      const { lid, name, group, tags, type} = device;
      let imageUrl = '';
      let vpnAccessLink: string |null = '-';
      let vpnEnabled: boolean|null = false;
      let vpnStatus: string|null = '-';
      let solutionLink: string |null = '-';
      let statusLine = null;
      let imageUrlpopup = '';
      if(statusUpdate[lid]) {
        const {lastSnapshot: {signedUrl='-'},status : {alive, diskUsageAlertLevel },} = statusUpdate[lid];
        imageUrl = signedUrl;

        if(diskUsageAlertLevel === 'danger') {
          statusLine = alive === false ? 'neutral' :'danger';
        } else if(diskUsageAlertLevel === 'caution') {
          statusLine = alive === false ? 'neutral' :'caution ';
        } else if(alive === true) {
          statusLine = 'good';
        } else if(alive === false) {
          statusLine = 'danger';
        } else {
          statusLine = 'neutral';
        }
      }
      if(vpnStatusUpdate[lid]) {
        const admin = vpnStatusUpdate[lid]?.admin || {url: null, enabled: null, status: null};
        const solution = vpnStatusUpdate[lid]?.solution || {url: null, enabled: null,};
        vpnAccessLink = admin.url || '-';
        vpnEnabled =  admin.enabled || false;
        vpnStatus = admin.status || '-';
        solutionLink = solution.url || '-';
      }

      imageUrlpopup = statusUpdate[lid]?.isImage ? imageUrl : '';

      const row: IRowData = {
        id: lid,
        header: {
          status: statusLine,
          image: imageUrl,
          mediaType: 'img',
          mediaUrl: imageUrlpopup,
          icon: type === 'folder' ? 'DevicesFolder': type === 'stream'? 'DevicesLiveEndpoint': 'DevicesScorerEdge'
        },
        columns: [
          { text: name },
          { customComponent: <MultilineContent contentArray={generateTagCategoryColumn(group, tags)} />},
          { customComponent: <MultilineContent contentArray={generateLinkColumn(vpnAccessLink, solutionLink, vpnEnabled, vpnStatus)} />},
          { customComponent: vpnStatus === 'active' ? <ActionButtonsContainer buttonsConfig={generateConfigButtons(lid, name, true, vpnEnabled)} /> : <div /> },
          { customComponent: <MultilineContent contentArray={generateSwitchColumn(lid, name, vpnStatus, vpnEnabled)} />}
        ]
      };
      return row;
    });
    setResultCount(data.length);
    if(data.length === 0) {
      return emptyRow;
    }
    return data;
  }, [devices, pageSize, currentPage, filterDevice, statusUpdate, sortDevices, generateTagCategoryColumn, generateLinkColumn, generateConfigButtons, generateSwitchColumn, vpnStatusUpdate]);

  useEffect(()=>{
    setTags(getTagList);
    setCategories(getCategoryList);
  },[devices, getTagList, getCategoryList]);

  useEffect(() => {
    setRowData(generateRowData());
  }, [generateRowData, devices, statusUpdate]);

  useEffect(() => {
    setTags(getTagList);
    setCategories(getCategoryList);
    setRowData(generateRowData());
  }, [devices, generateRowData, getTagList, getCategoryList, statusUpdate]);

  const onChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  }, []);

  const onChangeCategory = useCallback((filter: any) => {
    if(selectedCategory === filter){
      setSelectedCategory('');
    }else{
      setSelectedCategory(filter);
    }
    setCurrentPage(1);
  }, [selectedCategory]);

  const onChangeTag = useCallback((filter: string[]) => {
    setSelectedTag([...filter]);
    setCurrentPage(1);
  }, []);

  const onSizePerPageChange = useCallback((size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onHandleClearAllLink = useCallback(() => {
    setSearchText('');
    setSelectedCategory('');
    setSelectedTag([]);
    setSortBy({
      sort:'name',
      ascending: true,
      title: t('Webgateway:sorting.deviceName')
    });
  }, [t]);

  const removeTag = useCallback((tag: string) => {
    setSelectedTag(selectedTag.filter(t => t !== tag));
  }, [selectedTag]);

  const removeCategory = useCallback(() => {
    setSelectedCategory('');
  }, []);

  const removeSearch = useCallback(() => {
    setSearchText('');
  }, []);

  const sort = useCallback((sortBy: ISortBy) => {
    setSortBy(sortBy);
  }, []);

  return (
    <Content>
      <Wrapper enableContent={isFeatureEnabled}>
        <PageHeaderContainer title={t('Common:webgateway')} icon='Gateway' />
        <PageHeaderText>
          {t('Webgateway:introductionText')}
        </PageHeaderText>
        <SearchFieldContainer>
          <LeftContainer>
            <InputWrapper><FilterInput type='text' placeholder={t('Webgateway:filters.searchByDeviceName')} value={searchText} onChange={onChangeInput} /></InputWrapper>
            <Select icon='MetaCategories' title={t('Webgateway:filters.category').toString()} width='auto' onSelect={onChangeCategory} selected={selectedCategory} list={categories} />
            <SelectMulti icon='MetaTags' title={t('Webgateway:filters.tags')} onSelect={onChangeTag} selected={selectedTag} list={tags} showFilter />
          </LeftContainer>
          <SortBy title={t('Webgateway:sorting.sorting')} selected={sortBy} onSelect={sort} list={sortByList} />
        </SearchFieldContainer>
        <ResultInfoContainer>
          <ResultCountLabel>
            {t('Webgateway:filters.showingResults')}  ({resultCount}):
            {
              selectedCategory !== '' &&
                <FilterContainer>
                  <FilterListIcon><Icon icon='MetaCategories' color='dimmed' size={14} /></FilterListIcon>
                  <FilterText>{selectedCategory}</FilterText>
                  <ClearItem onClick={removeCategory}><Icon icon='CloseCompact' color='dimmed' size={14} /></ClearItem>
                </FilterContainer>
            }
            {
              searchText !== '' &&
                <FilterContainer>
                  <FilterListIcon><Icon icon='Search' color='dimmed' size={14} /></FilterListIcon>
                  <FilterText>{searchText}</FilterText>
                  <ClearItem onClick={removeSearch}><Icon icon='CloseCompact' color='dimmed' size={14} /></ClearItem>
                </FilterContainer>
            }
            {
              selectedTag.map((tag, key) => (

                <FilterContainer key={key}>
                  {selectedCategory !== '' || (selectedTag.length > 1 && key !== 0) ? <SepratorContainer /> : <div />}
                  <FilterListIcon><Icon icon='MetaTags' color='dimmed' size={14} /></FilterListIcon>
                  <FilterText>{tag}</FilterText>
                  <ClearItem onClick={() => removeTag(tag)}><Icon icon='CloseCompact' color='dimmed' size={14} /></ClearItem>
                </FilterContainer>
              ))
            }
            {
              ( selectedTag.length !== 0 || searchText !== '' || selectedCategory !== '') &&
                <ClearLinkAll onClick={onHandleClearAllLink}>{t('Webgateway:filters.clearAll')}</ClearLinkAll>
            }
          </ResultCountLabel>
          <div>
            <SortLabel>{t('Webgateway:filters.sortedBy')}</SortLabel>
            <SortAttributeLabel> {t(`Webgateway:sorting.${sortBy.sort}`)}</SortAttributeLabel>
          </div>
        </ResultInfoContainer>
        <TypeTable columnConfig={tableColumns} rows={rowData} hasTypeIcon hasThumbnail hasStatus isLoading={loading} emptyTableText={t('Webgateway:noDeviceFound')} />

        <PaginationContainer>
          <Pagination
            pageSizeOptions={sizeOptions}
            totalPages={totalPages}
            defaultPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onSizePerPageChange}
            onPageChange={onPageChange}
            pageSizeText={t('Webgateway:pagination.itemsPerPage')}
            prevPageText={t('Webgateway:pagination.page')}
          />
        </PaginationContainer>
      </Wrapper>
    </Content>
  );
};

export default WebGateway;