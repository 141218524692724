import React, { useCallback, useEffect, useState }  from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import awsConfig from './lib/amplify';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import logoMarkSvg from './svg/logo-mark.svg';
import logoTextSvg from './svg/logo-text.svg';
import FeatureEnableDrawer from './components/FeatureEnableDrawer';
//pages

import WebGateway from './pages/WebGateway';
import Usage from './pages/Usage';


import { MainMenu, Layout, TopBar } from 'scorer-ui-kit';
import menu from './pages/menu.json';
import Login from './pages/Login';
import styled from 'styled-components';
import { useUsername } from './hooks/useUsername';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useFeatureEnable } from './hooks/useFeatureEnable';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export interface ContextProps {
  isFeatureEnabled: boolean,
  setIsFeatureEnabled: (value: boolean) => void
}
export const FeatureEnabledContext = React.createContext<ContextProps>({
  isFeatureEnabled: false,
  setIsFeatureEnabled: () => { }
});

export const GatewayModalContext = React.createContext<GatewayModalContext>({
  modalClose: false,
  setModalClose: () => { }
});

Amplify.configure(awsConfig);


const App: React.FC<{}> = () => {
  const {username, signOut} = useUsername();
  const { t } = useTranslation(['Common']);
  const [translatedMenu, setTranslatedMenu] = useState(menu);
  const {state: {featureEnabled}, actions:{fetchFeatureEnabled}} = useFeatureEnable();
  const [isFeatureEnabled, setIsFeatureEnabled] = useState<boolean>(true);
  const [modalClose, setModalClose] = useState<boolean>(false);

  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  },[]);

  useEffect(() => {
    const translatedMenu = {
      items: menu.items.map(({title,...rest}) => ({title: t(title),...rest}))
    };
    setTranslatedMenu(translatedMenu);
  },[t]);

  const getEnableFeature = useCallback(() =>{
    fetchFeatureEnabled();
    setIsFeatureEnabled(featureEnabled);
  },[fetchFeatureEnabled, featureEnabled]);

  useEffect(()=>{
    getEnableFeature();
  },[getEnableFeature]);

  return (
    <Router>
      <Layout>
      <FeatureEnabledContext.Provider value={{isFeatureEnabled, setIsFeatureEnabled}}>
          <MainMenu content={translatedMenu} logoMark={logoMarkSvg} logoText={logoTextSvg} />
          <GatewayModalContext.Provider value={{ modalClose, setModalClose }}>
            <MainContainer>
              <TopBar
                userDrawerBespoke={<FeatureEnableDrawer username={username} />}
                hasSearch={false}
                loggedInUser={username}
                logoutLink='/'
                onLogout={signOut}
                hasLanguage
                onLanguageToggle={onLanguageChange}
              />
              <Switch>
              <Route  path='/' exact render={() => <WebGateway username={username} />} />
                {/* <Route path='/' exact component={WebGateway} /> */}
                <Route path='/usage' exact component={Usage} />
              </Switch>
            </MainContainer>
          </GatewayModalContext.Provider>
          </FeatureEnabledContext.Provider>
      </Layout>
    </Router>
  );
};

export default withAuthenticator(App, false,[
  <Login key='login' onLogin={({username, password}) =>  Auth.signIn(username,password)} />
]);