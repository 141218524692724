import * as React from 'react';
import styled from 'styled-components';
import { RowCss } from '../tools/common';
import { Icon } from 'scorer-ui-kit';

const InputSearch = styled.input<{width?: string}>`
  font-family: ${({ theme }) => theme.fontFamily.data};
  outline: none;
  border: solid 1px hsl(0, 0%, 92%);
  padding-left: 33px;
  padding-top: 3px;
  width: ${props => props.width};
  position: relative;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  ::placeholder {
    opacity: 0.65;
    font-size: 14px;
    font-style: italic;
    color: hsl(0, 0%, 32%);
  }
  &:focus {
    color: hsl(208, 8%, 39%);
  }
`;

const Container = styled.div`
  ${RowCss}
  svg{
    position: absolute;
    left: 10px;
    top: 18px;
    fill: hsl(0, 0%, 0%,0.32);
    stroke: hsl(0, 0%, 0%,0.32);
    z-index: 1;
  }
`;

interface Iprops extends React.InputHTMLAttributes<HTMLInputElement> {
  className?:string;
  width?: string;
}

const SearchInput: React.FC<Iprops> = ({className, width, ...props}) => {
  return <Container className={className}><Icon icon='Search' size={15} color='dimmed' /><InputSearch {...props} width={width} /></Container>;
};

export default SearchInput;
