/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const devicesQuery = /* GraphQL */ `
  query Devices {
    devices {
      name
      lid
      tags
      type
      group
    }
  }
`;

export const deviceStatusQuery = /* GraphQL */ `
  query DeviceStatus($deviceID: String!) {
    deviceStatus(deviceID: $deviceID) {
      lastSnapshot {
        signedUrl
        time
      }
      status {
        alive
        diskUsageAlertLevel
        vpnIp
      }
    }
  }
`;

export const getWebgateStatusQuery = /* GraphQL */ `
  query GetWebgateStatus($deviceID: ID!) {
    getWebgateStatus(deviceID: $deviceID) {
      solution {
        status
        url
        enabled
        lastUpdated
      }
      admin {
        status
        url
        enabled
        lastUpdated
      }
    }
  }
`;

export const isWebgateEnabledQuery = /* GraphQL */ `
  query IsWebgateEnabled {
    isWebgateEnabled
  }
`;