import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Label, Switch, useNotification, INotificationProps } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { enableWebgateVPN, isWebgateEnabled, disableWebgateVPN } from '../lib/api_config';
import { FeatureEnabledContext, ContextProps, GatewayModalContext } from '../App';
import { useFeatureEnable } from '../hooks/useFeatureEnable';

const CustomContentContainer = styled.div`
  border-bottom: ${({theme: {colors}}) => colors.divider} 1px solid;
`;

const NotificationLabel = styled(Label)`
  height: 16px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 600;
  color: rgba(88, 88, 88, 0.9);
`;

const EnableLabel = styled(Label)`
  width: auto;
  flex-basis: 118px;
  height: 16px;
  padding-top: 3px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  color: rgba(88, 88, 88, 0.65);
`;

const FeatureInfoLabel = styled.label`
  font-size: 12px;
  // font-style: italic;
  line-height: 1.67;
  color: #8b9196;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 1px;
  height: 40px;
`;

const FeatureEnableContainer = styled.div`
  margin: 17px 23px 17px 20px;
`;
interface ToggleState {
  state: 'default' | 'disabled' | 'loading' | 'locked' | 'failure' | undefined;
}

const FeatureEnableDrawer: React.FC<{username:string}> = ({username}) => {

  const {isFeatureEnabled, setIsFeatureEnabled} : ContextProps = useContext(FeatureEnabledContext);
  const {actions:{ putFeatureEnabled}} = useFeatureEnable();
  const { t } = useTranslation(['Common']);
  const { sendNotification } = useNotification();
  const [toggleState, setToggleState] = useState<ToggleState['state']>();
  const { setModalClose } = useContext(GatewayModalContext);

  const notiInfoSuccessEnabled: INotificationProps = {
    type: 'success',
    message: t('Webgateway:notificationMessage.successMessage'),
  };

  const enableWebgate = useCallback(async () => {
    setModalClose(true);
    await enableWebgateVPN();
    setIsFeatureEnabled(true);
    putFeatureEnabled(true, username);
    sendNotification(notiInfoSuccessEnabled);
    setToggleState('default');
  },[notiInfoSuccessEnabled, sendNotification, setIsFeatureEnabled, putFeatureEnabled, setModalClose, username]);

  const notiInfoDisabled: INotificationProps = {
    type: 'info',
    message: t('Webgateway:notificationMessage.disableMessage'),
    actionTextButton: t('Webgateway:notificationMessage.enableMessageNow'),
    onTextButtonClick: () => enableWebgate(),
    isPinned: false
  };

  const disableWebgate = useCallback(async () => {
    setModalClose(true);
    await disableWebgateVPN(); 
    setIsFeatureEnabled(false);
    setToggleState('default');
    putFeatureEnabled(false, username);
    sendNotification(notiInfoDisabled);
    localStorage.setItem(`${username}_show_modal_popup_first_time`, 'false');
  },[setIsFeatureEnabled, putFeatureEnabled, sendNotification, notiInfoDisabled, setModalClose, username]);

  const onChangeFeatureEnable = useCallback(async(event) =>{
    setToggleState('loading');
    if(event){
      enableWebgate();
    } else {
      disableWebgate();
    }
  },[enableWebgate,disableWebgate]);

  const checkIsWebgateEnabled = useCallback(async () => {
    const enabled = await isWebgateEnabled();
    if(enabled) {
      setIsFeatureEnabled(enabled);
    } else {
      setIsFeatureEnabled(enabled);
    }
  },[setIsFeatureEnabled]);

  useEffect(()=>{
    checkIsWebgateEnabled();
  },[checkIsWebgateEnabled]);

  return(
    <CustomContentContainer>
      <FeatureEnableContainer>
        <NotificationLabel htmlFor='feature_enable' labelText={t('Common:featureEnable')} />
        <SwitchContainer>
          <EnableLabel htmlFor='feature_enable_switch' labelText={isFeatureEnabled ? t('Common:enabled') : t('Common:disabled')} />
          <Switch state={toggleState} checked={isFeatureEnabled} onChangeCallback={onChangeFeatureEnable} />
        </SwitchContainer>
        <FeatureInfoLabel>{t('Common:featureInfoLabel')}</FeatureInfoLabel>
      </FeatureEnableContainer>
    </CustomContentContainer>
  );
};

export default FeatureEnableDrawer;