import { css } from 'styled-components';

export const RowCss = css`
  position:relative;
  display: flex;
  flex-direction: row;
  align-items:center;
`;

export const resetButtonStyles = css`
  background-color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  user-select: none;
`;

export type SelectType = 'primary' | 'toolbar';